import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./MyProfile.scss";

interface IMyProfile {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const MyProfile = ({ setSelectedGroup }: IMyProfile) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <>
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.MyProfile")}
        </span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=profile&page=details",
          }}
        >
          <i className="user-nav-icon bc-i-user" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.PersonalDetails")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=profile&page=change-password",
          }}
        >
          <i className="user-nav-icon bc-i-change-password" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.ChangePassword")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=profile&page=verify-account",
          }}
        >
          <i className="user-nav-icon bc-i-verify-account" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.VerifyAccount")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search:
              "?profile=open&account=profile&page=two-factor-authentication",
          }}
        >
          <i className="user-nav-icon bc-i-two-factor-authentication" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.TwoStepAuthentication")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </>
  );
};

export { MyProfile };
