import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { CasinoInfo } from "../../../services";
import { CasinoCart } from "../casinoCart/CasinoCart";

import "./LiveCasinoSlot.scss";

const LiveCasinoSlot = () => {
  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  const [checkedGame, setCheckedGame] = useState(-1);
  useEffect(() => {
    async function getCasinoGames() {
      const res = await CasinoInfo.games(
        `/1?current_page=1&limit=4&page=1&is_mobile=true`
      );
      setCasinoGames(res.data.data);
    }
    getCasinoGames();
  }, []);
  return (
    <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
      <div className="page-view-more">
        <h3 className="view-title-more ellipsis custom-font-size">
          {t("navHeader.LiveCasino")}
        </h3>
        <Link className="view-see-more" to={"/tr/casino/slots/"}>
          {t("casino.More")}
          <i className="view-arrow-more bc-i-small-arrow-right" />
        </Link>
      </div>
      <div className="games-horiz casino-game-item-amount">
        <div
          className="games-horiz-scroll"
          data-scroll-lock-scrollable=""
          style={{ gridTemplateRows: "auto" }}
        >
          {casinoGames?.map((item) => {
            return (
              <CasinoCart
                cartInfo={item}
                key={item.id}
                checkedGame={checkedGame}
                setCheckedGame={setCheckedGame}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { LiveCasinoSlot };
