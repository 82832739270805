import React from "react";
import { Link } from "react-router-dom";

import "./ExchangeShopPage.scss";

const ExchangeShopPage = () => {
  return (
    <div className="bonusExchangeWrapper-bc" data-scroll-lock-scrollable="">
      <p className="exchangePoints">Exchangeable points: 0</p>
      <ul className="bonusExchangeList-bc">
        <li className="bonusExchangeListEl-bc">
          <div className="bonusExchangeListElThumbWrapper-bc">
            <img
              src="https://cmsbetconstruct.com/content/images/product/4e793e48ef1aefc5c6fb7741d4ad6db5_productImage.png"
              className="bonusExchangeListElThumb-bc"
              alt=""
            />
          </div>
          <div className="bonusExchangeListElInfo-bc">
            <div className="bonusExchangeListElInfoContain-bc">
              <h2 className="bonusExchangeListElTitle-bc">5 Free Spins</h2>
              <span className="bonusExchangeListElPts-bc">62,216 Pts.</span>
            </div>
            <div className="bonusExchangeListElInfo-btn-bc">
              <Link
                className="btn a-color"
                to={{
                  search:
                    "?profile=open&account=bonuses&page=exchange-shop&shopId=311",
                }}
              >
                Get It
              </Link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export { ExchangeShopPage };
