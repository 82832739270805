import React from "react";
import { mockCouruselMainType } from "../../../pages/Main/mockCourusel/mockCouruselMain";

import "./CaruselCart.scss";


interface CaruselCart {
  cartCaruselInfo: mockCouruselMainType;
}

const CaruselCart = ({ cartCaruselInfo }: CaruselCart) => {
  return (
    <div className="carousel-slide " style={{ width: "100%", left: "0%" }}>
      <div className="sdr-item-holder-bc">
        <a className="sdr-item-bc">
          <img
            src={cartCaruselInfo.img}
            loading="lazy"
            className="sdr-image-bc"
            alt=""
            title={cartCaruselInfo.title}
          />
        </a>
      </div>
    </div>
  );
};

export { CaruselCart };
