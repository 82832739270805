import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./ProfileNav.scss";

interface IProfileNav {
  page: string;
}

const ProfileNav = ({ page }: IProfileNav) => {
  return (
    <div className="hdr-navigation-scrollable-bc">
      <div
        className="hdr-navigation-scrollable-content"
        data-scroll-lock-scrollable=""
      >
        <Link
          className={`hdr-navigation-link-bc ${
            page === "details" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=details",
          }}
        >
          {t("admin.PersonalDetails")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "change-password" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=change-password",
          }}
        >
          {t("admin.ChangePassword")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "verify-account" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=profile&page=verify-account",
          }}
        >
          {t("admin.VerifyAccount")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "two-factor-authentication" ? "active" : ""
          }`}
          to={{
            search:
              "profile=open&account=profile&page=two-factor-authentication",
          }}
        >
          {t("admin.TwoStepAuthentication")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
      </div>
    </div>
  );
};

export { ProfileNav };
