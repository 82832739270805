import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";
import { setLanguage } from "../../../../../store/action/settings/setlanguage";

import "./FooterSetteings.scss";

const FooterSetteings = () => {
  const { en } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const language = useTypeSelector((data) => data.settingsReducer.language);

  const handleItemSelect = (item: React.ChangeEvent<HTMLSelectElement>) => {
    let languageSelected = language;
    if (item.target.value === "tr") {
      languageSelected = {
        languageShort: "tr",
        languageName: "Türkçe",
        languageAbs: "TUR",
        languageFlag: "turkey",
      };
    } else if (item.target.value === "en") {
      languageSelected = {
        languageShort: "en",
        languageName: "English",
        languageAbs: "ENG",
        languageFlag: "unitedkingdom",
      };
    }
    dispatch(setLanguage(languageSelected));
    const currentPath = location.pathname;
    const newPath = en
      ? currentPath.replace(en, languageSelected.languageShort)
      : currentPath;
    navigate(newPath);
    window.location.reload();
  };
  return (
    <div className="ftr-decimal-language-row">
      <div className="form-control-bc select  filled">
        <label className="form-control-label-bc inputs">
          <select className="form-control-select-bc ellipsis">
            <option value={0}>Ondalık</option>
            <option value={1}>Kesirli</option>
            <option value={2}>Amerikan</option>
            <option value={3}>HongKong</option>
            <option value={4}>Malay</option>
            <option value={5}>Indo</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <span className="form-control-title-bc ellipsis">Oran formatı</span>
        </label>
      </div>
      <div className="form-control-bc select  filled">
        <label className="form-control-label-bc inputs">
          <i
            className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${language.languageFlag}`}
          />
          <select
            className="form-control-select-bc ellipsis"
            onChange={handleItemSelect}
            defaultValue={language.languageAbs}
          >
            {language.languageShort === "tr" ? (
              <>
                <option value="tr">Türkçe</option>
                <option value="en">English</option>
              </>
            ) : null}
            {language.languageShort === "en" ? (
              <>
                <option value="en">English</option>
                <option value="tr">Türkçe</option>
              </>
            ) : null}
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <span className="form-control-title-bc ellipsis">Dil</span>
        </label>
      </div>
    </div>
  );
};

export { FooterSetteings };
