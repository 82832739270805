import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";
import { Carusel } from "../../components/carusel/Carusel";
import { CasinoGamesSlot } from "../../components/main/casinogamesslot/CasinoGamesSlot";
import { FuturedGamesSlot } from "../../components/main/futuredgamesslot/FuturedGamesSlot";
import { GamesSlot } from "../../components/main/gamesslot/GamesSlot";
import { JackpotSlortar } from "../../components/jackpotslortar/jackpotslortar";
import { LiveCasinoSlot } from "../../components/main/livecasinoslot/LiveCasinoSlot";
import { PromoSlortar } from "../../components/main/promoslortar/PromoSlortar";
import { TelegramSlot } from "../../components/main/telegramslot/TelegramSlot";

import { mockCouruselMain } from "./mockCourusel/mockCouruselMain";
import "./Main.scss";

const Main = () => {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);
  return (
    <Layout containerStyles="is-home-page " navHeader="">
      <div className="layout-content-holder-bc">
        <Carusel mockCarusel={mockCouruselMain} />
        <JackpotSlortar load={load} />
        <PromoSlortar />
        <TelegramSlot />
        <CasinoGamesSlot />
        <LiveCasinoSlot />
        <GamesSlot />
        <FuturedGamesSlot />
      </div>
    </Layout>
  );
};

export { Main };
