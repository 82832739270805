import { api } from "../http/index";
import {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { CasinoGameType } from "../common/types/Casino/CasinoGameType";
import { GameProvider, GameProviderRequest } from "../common/types/Casino/GameProvider";
import { login } from "../common/types/Auth/login";
import { UserInformation } from "../common/types/AdminInfo/UserInformation";
import { userDeposit } from "../common/types/AdminInfo/userDeposit";
import { userPay, userPayBody } from "../common/types/AdminInfo/userPay";
import { userBalanceType } from "../common/types/AdminInfo/userBalanceType";
import { userBets } from "../common/types/AdminInfo/userBets";
import { register } from "../common/types/Auth/register";
import { changePassword } from "../common/types/Auth/changePassword";
import { CategoriesType, CategoriesTypeRequest } from "../common/types/Casino/CategoriesType";
import { FavoritCasinoResponceType } from "../common/types/Casino/FavoritCasinoResponceType";

const token = process.env.REACT_APP_API_ORIGIN_TOKEN;

type CategoriesTypeReguest = {
  current_page: number;
  data: CategoriesType[];
  first_page_url: string;
  from: number;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
};

class AuthService {
  static async login({
    username,
    password,
  }: login): Promise<AxiosResponse<string>> {

    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return api.post<string>("api/login", { username, password });
  }
  static async register({
    address,
    affid,
    birthday,
    country,
    currency,
    cxd,
    email,
    firstname,
    gender,
    language,
    lastname,
    passport,
    password,
    password_confirmation,
    phone,
    promocode,
    state,
    username,
    zipcode,
  }: register): Promise<AxiosResponse<string>> {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    return api.post<string>("api/register", {
      address,
      affid,
      birthday,
      country,
      currency,
      cxd,
      email,
      firstname,
      gender,
      language,
      lastname,
      passport,
      password,
      password_confirmation,
      phone,
      promocode,
      state,
      username,
      zipcode,
    });
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<AxiosResponse<string>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return api.post<string>(
      "/api/change-password",
      {
        current_password,
        password,
        password_confirmation,
      },
      {
        headers,
      }
    );
  }
}

class CasinoInfo {
  static async setFavoritGame(data: {
    game_id: number;
    type: number;
  }): Promise<AxiosResponse<FavoritCasinoResponceType>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return api.post<FavoritCasinoResponceType>(`api/add-to-favorite`, data, {
      headers,
    });
  }
  static async providers(
    searchParams?: string
  ): Promise<AxiosResponse<GameProviderRequest>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<GameProviderRequest>(`/providers${searchParams}`, { headers });
  }

  static async categories(
    searchParams: string
  ): Promise<AxiosResponse<CategoriesTypeRequest>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<CategoriesTypeRequest>(`/categories-with-games${searchParams}`, {
      headers,
    });
  }

  static async games(searchParams: string): Promise<AxiosResponse<any>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<any>(`/category-with-games${searchParams}`, { headers });
  }

  static async searchGames(searchParams: string): Promise<AxiosResponse<any>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<any>(`/search-games${searchParams}`, { headers });
  }

  static async casinoPlay(
    searchParams: string
  ): Promise<AxiosResponse<CasinoGameType>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<CasinoGameType>(`/casino${searchParams}`, { headers });
  }
  static async favoritGames(): Promise<
    AxiosResponse<FavoritCasinoResponceType[]>
  > {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<FavoritCasinoResponceType[]>(`/api/favorites-games`, {
      headers,
    });
  }
}

class AdminPanel {
  static async userInfo(): Promise<AxiosResponse<UserInformation>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.post<UserInformation>("/api/user", { headers });
  }

  static async userBalance(): Promise<AxiosResponse<userBalanceType>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<userBalanceType>("/api/update-user-balance", { headers });
  }
  static async userBets(params: string): Promise<AxiosResponse<userBets>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<userBets>(`/user-dashboard/get-all-bets${params}`, {
      headers,
    });
  }
  static async userDeposit(
    params: string
  ): Promise<AxiosResponse<userDeposit[]>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;
    return api.get<userDeposit[]>(`/payment-methods${params}`, {
      headers,
    });
  }
  static async userPay({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<userPayBody>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return api.post<userPayBody>(
      `/api/user-dashboard/set-deposit${params}`,
      data,
      {
        headers,
      }
    );
  }
  static async userPayManual({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<userPayBody>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return api.post<userPayBody>(
      `/api/user-dashboard/set-manual-deposit${params}`,
      data,
      {
        headers,
      }
    );
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<AxiosResponse<userPayBody>> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return api.post<userPayBody>(
      `/api/user-dashboard/set-deposit`,
      { current_password, password, password_confirmation },
      {
        headers,
      }
    );
  }
}

export { AuthService, CasinoInfo, AdminPanel };
