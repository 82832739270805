import React from "react";
import { Layout } from "../../components/layout";

import "./MaltbahisCarck.scss";

const MaltbahisCarck = () => {
  return (
    <Layout
      containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
      navHeader="maltbahiscarck"
      loadStop={false}
      footerNav="casino"
    >
      <div className="layout-content-holder-bc">
        <iframe
          className="iframe-full-page"
          title="https://luckywheel.maltbahis287.com/?langId=en&partnerId=1873892&SourceId=1"
          src="https://luckywheel.maltbahis287.com/?langId=en&partnerId=1873892&SourceId=1"
          allowFullScreen={true}
          allow="autoplay; microphone *"
        />
      </div>
    </Layout>
  );
};

export { MaltbahisCarck };
