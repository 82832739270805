import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userDeposit } from "../../../../../../../common/types/AdminInfo/userDeposit";
import { AdminPanel } from "../../../../../../../services";

import "./WithdrowPage.scss";

interface IWithdrowPage {
  setSelectedPaymentMethod: React.Dispatch<
    React.SetStateAction<undefined | userDeposit>
  >;
}

const WithdrowPage = ({ setSelectedPaymentMethod }: IWithdrowPage) => {
  const nanvigate = useNavigate();
  const [paymentMethods, setPaymentMethods] = useState<userDeposit[] | []>([]);

  useEffect(() => {
    async function getPayemntMethods() {
      const res = await AdminPanel.userDeposit("?filter[type]=2");
      setPaymentMethods(res.data);
      setSelectedPaymentMethod(res.data[0]);
    }
    getPayemntMethods();
  }, []);

  const handleSelectMethod = (item: userDeposit) => {
    const selectedMethodName = item.payment_name || item.name;
    setSelectedPaymentMethod(item);
    nanvigate({
      search: `?profile=open&account=balance&page=withdraw&selectedGroup=all&selectedMethod=${selectedMethodName}`,
    });
  };
  return (
    <div className="dep-w-info-bc" data-scroll-lock-scrollable="">
      <div className="m-block-nav-items-bc">
        {paymentMethods.map((item) => {
          return (
            <div
              className="m-nav-items-list-item-bc "
              key={item.id}
              onClick={() => handleSelectMethod(item)}
            >
              <div className="nav-ico-w-row-bc">
                <img
                  className="payment-logo"
                  // src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10795.png"
                  alt=""
                />
              </div>
              <p className="m-nav-list-item-title-bc">{item.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { WithdrowPage };
