import React from "react";
import { Layout } from "../../components/layout";
import { PromotionsType } from "../../components/promotions/PromoitonsType/PromotionsType";

import "./Promotions.scss";

const Promotions = () => {
  return (
    <Layout containerStyles="is-home-page " navHeader="promotions">
      <div className="layout-content-holder-bc">
        <PromotionsType />
        <section className="promotionsList-bc custom-promoitons-list">
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="Drop & Wins"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_2216ffd50f677b09c4634e5cfdfe6d85.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              Drop &amp; Wins
            </h3>
          </article>
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="MYTHICAL GARDEN"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_e00801723440876f8e9732213c20f376.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              MYTHICAL GARDEN
            </h3>
          </article>
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="Wazdan - Mystery Drop"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_78e431a5f09a8f84eb44a6a553b3105b.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              Wazdan - Mystery Drop
            </h3>
          </article>
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="Her Yatırıma Aviator Freebet"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_02b1e9a724621904f6e99dd0bc8bfd83.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              Her Yatırıma Aviator Freebet
            </h3>
          </article>
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="1500 TL'ye kadar %100 Casino Hosgeldin Bonusu + 200 Freespin"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_16f64aca258e9c49aa9e92c02d122a89.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              1500 TLye kadar %100 Casino Hosgeldin Bonusu + 200 Freespin
            </h3>
          </article>
          <article className="promotionsListEl-bc">
            <img
              className="promotionsArticleThumbnail-bc"
              alt=""
              title="Spor Bahisleri Özel Seçenekleri"
              src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_59e785d22a8ab7a482ff5089be45fd67.webp"
            />
            <h3 className="promotions-article-title ellipsis">
              Spor Bahisleri Özel Seçenekleri
            </h3>
          </article>
        </section>
      </div>
    </Layout>
  );
};

export { Promotions };
