import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import i18n from "../i18n";
import { setLanguage } from "../store/action/settings/setlanguage";
interface RouteWrapperProps {
  element: React.ReactNode;
}

const RouteWrapper = ({ element: Element }: RouteWrapperProps) => {
  const { en } = useParams();
  const local = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (en === "en" || en === "tr") {
      i18n.changeLanguage(en);

      if (en === "en") {
        dispatch(
          setLanguage({
            languageShort: "en",
            languageAbs: "ENG",
            languageFlag: "unitedkingdom",
            languageName: "English",
          })
        );
      } else if (en === "tr") {
        dispatch(
          setLanguage({
            languageShort: "tr",
            languageAbs: "TRY",
            languageFlag: "turkey",
            languageName: "Türkçe",
          })
        );
      }
    }
  }, [dispatch, en, local]);

  return <>{Element}</>;
};

export { RouteWrapper };
