type IsetModalLogin = {
  type: string;
  payload: boolean;
};

function setModalPopap(payload: boolean): IsetModalLogin {
  return {
    type: 'SET_MODAL_POPAP',
    payload,
  };
}

export { setModalPopap };
