import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./LoyaltyPointsNav.scss";

interface ILoyaltyPointsNav {
  page: string;
}

const LoyaltyPointsNav = ({ page }: ILoyaltyPointsNav) => {
  return (
    <div className="hdr-navigation-scrollable-bc">
      <div
        className="hdr-navigation-scrollable-content"
        data-scroll-lock-scrollable=""
      >
        <Link
          className={`hdr-navigation-link-bc ${
            page === "loyalty-points" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=loyalty-points",
          }}
        >
          {t("admin.LoyaltyPoints")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "exchange-shop" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=exchange-shop",
          }}
        >
          {t("admin.ExchangeShop")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "sport" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=sport",
          }}
        >
          {t("admin.SportBonus")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "bonus-history" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=bonus-history",
          }}
        >
          {t("admin.Bonushistory")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "casino" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=casino",
          }}
        >
          {t("admin.CasinoBonus")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "promo-code" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=promo-code",
          }}
        >
          {t("admin.Promocode")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "casino-free-spins" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=bonuses&page=casino-free-spins",
          }}
        >
          {t("admin.CasinoFreeSpins")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
      </div>
    </div>
  );
};

export { LoyaltyPointsNav };
