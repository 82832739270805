import React from "react";
import { CenterPannelButton } from "./components/CenterPannelButton/CenterPannelButton";
import { MainHeader } from "./components/MainHeader/MainHeader";
import { MenuNavigation } from "./components/MenuNavigation/MenuNavigation";
import { NavHeader } from "./components/NavHeader/NavHeader";
import "./Header.scss";

interface IHeader {
  setSmartPanelVisibale: React.Dispatch<React.SetStateAction<boolean>>;
  smartPanelVisibale: boolean;
  headerHidden: boolean;
  centerButtonVisivaly: boolean;
  menuNavigationVisibaly: boolean;
  setMenuNavigationVisibaly: React.Dispatch<React.SetStateAction<boolean>>;
  navHeader: string;
}

const Header = ({
  setSmartPanelVisibale,
  smartPanelVisibale,
  headerHidden,
  centerButtonVisivaly,
  menuNavigationVisibaly,
  setMenuNavigationVisibaly,
  navHeader,
}: IHeader) => {
  return (
    <div className="layout-header-holder-bc">
      <div className="hdr-dynamic-content" />
      <div className={`header-bc ${headerHidden ? "header-bc-hide" : ""} `}>
        <MainHeader
          setSmartPanelVisibale={setSmartPanelVisibale}
          smartPanelVisibale={smartPanelVisibale}
        />
        <NavHeader navHeader={navHeader} />

        <CenterPannelButton centerButtonVisivaly={centerButtonVisivaly} />
      </div>
      {menuNavigationVisibaly ? (
        <MenuNavigation setMenuNavigationVisibaly={setMenuNavigationVisibaly} />
      ) : null}
    </div>
  );
};

export { Header };
