import React from "react";

import "./CookieNoticeModal.scss";

const CookieNoticeModal = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">Çerez Bildirimi</span>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p>
          <strong>Genel</strong>
        </p>
        <p>
          Maltbahis her kullanıcıya en iyi şekilde yanıt verebilmek için
          çerezler kullanma hakkını saklı tutar. Maltbahis çerezler kullanmakla
          her kullanıcının tercihleri hakkında daha iyi bilgi sahibi olur ve
          siteyi her kullanıcının beklentilerine göre kişiselleştirebilir.
          Çerezler her kullanıcının dil tercihini belirlemeye yardımcı olur ve
          böylece Maltbahis web sitesinde o dili otomatik şekilde seçebilir ve
          ayrıca kullanıcı tarafından oynanan bahislerin onun biletine ve
          hesabına bağlanmasını sağlar.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Çerezler</strong>
        </p>
        <p>
          <strong>Çerezler Nedir?</strong>
        </p>
        <p>
          Bir çerez, bir web sitesini ziyaret ederken bilgisayarınıza depolanan
          küçük bir metin dosyasıdır. Bu metin dosyası, daha sonraki bir tarihte
          web sitesini tekrar ziyaret ederken onun tarafından okunabilen
          bilgiler depolar. Çerezler ziyaret ettiğiniz web sitesinin sunucusu
          tarafından veya o web sitesinin ortakları tarafından
          yerleştirilebilir. Bir web sitesinin sunucusu yalnızca kendisinin
          yerleştirmiş olduğu çerezleri okuyabilir ve bilgisayarınızda veya
          mobil cihazınızda bulunan başka bilgilere erişimi yoktur. Çerezler
          bilgisayarınızda veya mobil cihazınızda tarayıcınızın klasöründe
          depolanır. Çerezler genellikler ziyaretçi ile web sitesi arasındaki
          etkileşimi daha hızlı ve daha kolay yapar ve ziyaretçinin bir web
          sitesinin farklı sayfaları arasında dolaşmasına yardım eder. Bu
          çerezlerin bazıları gereklidir çünkü aksi takdirde site düzgün şekilde
          çalışamaz. Diğer çerezler ziyaretçinin rahatlığı içindir: örneğin
          güvenli şekilde kullanıcı adınızı ve ayrıca dil tercihlerinizi
          hatırlarlar. Çerezler, bir web sitesini her ziyaret ettiğinizde aynı
          bilgileri doldurmanız gerekmeyeceği anlamına gelir.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Çerezler nereden gelir?</strong>
        </p>
        <p>
          Çerezlerin kökenine bağlı olarak birinci taraf ve üçüncü taraf
          çerezleri arasında ayırım yaparız.
          <br />
          <br />
          Birinci taraf çerezleri
          <br />
          <br />
          Birinci taraf çerezleri kendi yönettiğimiz, doğrudan çerezlerdir.
          Bunlar ziyaret edilen veya kullanılan Maltbahis çevirim içi hizmete
          özgüdür. Örneğin, düzgün çalışmak için çerezleri oluşturan ve
          yerleştiren, çevirim içi hizmettir.
          <br />
          <br />
          Üçüncü taraf çerezleri
          <br />
          <br />
          Üçüncü taraf çerezleri bizim tarafımızdan değil üçüncü bir tarafça
          yönetilen veya yerleştirilen çerezlerdir. Bu, bir Maltbahis çevirim
          içi hizmetini ziyaretiniz veya kullanımınız sırasında meydana gelir.
          Üçüncü taraf çerezleri böylece bir çevirim içi hizmeti ziyaretiniz
          veya kullanımınız vasıtası ile üçüncü taraflara bazı veriler
          gönderilmesini sağlar. Bunlar, örneğin sosyal medya çerezleridir veya
          reklamcılardan gelen ve reklamları görmenizi sağlayan çerezlerdir. Bu
          çerezleri biz yönetmediğimiz veya yerleştirmediğimiz halde bu
          çerezlerin üçüncü taraflarca bizim platformlarımız vasıtası ile
          yerleştirilmesinin olanaklı olduğunca kontrollü olmasını sağlamak için
          elimizden gelen çabayı gösteririz.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Maltbahis çerezleri nasıl kullanır?</strong>
        </p>
        <p>
          Maltbahis, size, kişisel tercihlerinize uygun duruma getirilmiş, daha
          iyi bir kullanıcı deneyimi sağlamamıza yardımcı olması için çerezler
          kullanır. Maltbahis çerezler kullanarak, Maltbahis web sitesini
          kullandığınız her seferde aynı bilgileri almamanızı veya girmek
          zorunda kalmamanızı sağlar. Çerezler ayrıca web sitesinin
          performansını optimize etmek için kullanılır. Maltbahis’ün web
          sitelerinde farklı türlerde çerezler kullanılmaktadır:
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>Gerekli çerezler</li>
          <li>İşlevsellik çerezleri</li>
          <li>Performans çerezleri</li>
          <li>Reklam Çerezleri</li>
        </ul>
        <p>&nbsp;</p>
        <p>Gerekli çerezler</p>
        <p>
          Bu çerezler web sitelerimizi ziyaret etmek ve web sitelerinin bazı
          bölümlerini kullanmak için vazgeçilmezdir. Örneğin, bunlar web
          sitesinin farklı bölümleri arasında gezinmenize ve formlar
          doldurmanıza olanak verir.
          <br />
          <br />
          Bu çerezleri, başka şeylerin yanı sıra şu yararları garanti etmek için
          kullanırız:
        </p>
        <ul>
          <li>
            Çeşitli sayfalarda girdiğiniz veya belirttiğiniz bilgileri
            hatırlamak. Böylece verilerinizin veya tercihlerinizin hepsini daima
            tekrar doldurmanız veya belirtmeniz gerekmez.
          </li>
          <li>
            Optimum bir video görünümü için arabellek boyutu ve ekranınızın
            çözünürlüğü gibi istenen ayarları kayıt etmek.
          </li>
          <li>
            Web sitemizin ekranınızdaki optimum görünümü için tarayıcı
            ayarlarınızı okumak.
          </li>
          <li>
            Web sitemizdeki reklam yerlerini olanaklı olan en iyi teknik şekilde
            doldurmak. Bu, her zaman aynı reklamların gösterilmesini
            önleyebilmemizi sağlar, reklam verenler reklamlarının etkisini
            ölçebilir veya reklam yeri vasıtası ile olası istismarları
            algılayabilir ve ele alabiliriz.
          </li>
          <li>
            Web sitesinin her zaman için erişilebilir durumda kalması için onu
            düzgün şekilde yüklemek.
          </li>
        </ul>
        <p>
          Bu çerezleri kullanmak için izin gerekli değildir ve bunlar etkin
          olmaktan çıkarılamaz.
        </p>
        <p>&nbsp;</p>
        <p>İşlevsellik çerezleri</p>
        <p>
          İşlevsel çerezler web sitelerimizin çalışmasını kolaylaştıran, onları
          ziyaretçiler için daha hoş duruma getiren ve daha kişiselleştirilmiş
          bir tarama yapabilmenizi sağlayan çerezlerdir. Örneğin, bu çerezler
          dil tercihinizi ve müşteri segmentinizi hatırlayabilir.
        </p>
        <p>&nbsp;</p>
        <p>Performans çerezleri</p>
        <p>
          Performans çerezlerini ziyaretçilerin web sitelerimizi kullanımı
          hakkında bilgi elde etmek, web sitelerimizin içeriğini iyileştirmek,
          onları ziyaretçilerin isteklerine uyarlamak ve onları daha kullanıcı
          dostu yapmak için kullanırız. Örneğin, özgün ziyaretçilerin sayısını
          sayan bir çerez ve en beğenilen sayfaların izini süren bir çerez
          bulunmaktadır.
          <br />
          <br />
          Performans çerezlerine istatistik çerezleri de denmektedir. Bunlar
          çevirim içi hizmetlerimizin nasıl kullanıldığı hakkında genel bilgi
          toplar. Bu şekilde topladığımız bilgilerin istatistik analizini
          yapabiliriz. Web sayfamıza nasıl geldiniz? Zamanınızın en çoğunu hangi
          (mobil) uygulamada harcadınız? İstatistiksel sonuçlar sayesinde
          çevirim içi hizmetlerimizin yapısı, gezinme ve içeriğini olanaklı
          olduğunca kullanıcı dostu yapabiliriz. Bu verileri ayrıca Maltbahis
          performansı hakkında rapor hazırlamak için veya katıldığımız anketler
          bağlamında kullanırız. Bu çerezleri kullanmak için izin gerekli
          değildir ve bunlar etkin olmaktan çıkarılamaz.
        </p>
        <p>&nbsp;</p>
        <p>Reklam Çerezleri</p>
        <p>
          Reklam çerezleri, sizin kullanıcı profilinizi oluşturmak için web
          sitelerimizdeki göz atma davranışınızı izler. Bu profil bunun ardından
          size kişisel ilgi alanlarınıza uyan reklamlar göstermek için
          kullanılır.
          <br />
          <br />
          Reklam çerezleri yalnızca web sitesini kullanımınız hakkında size bir
          şeyler söyleyen veriler toplayabilir. Örneğin, Maltbahis profilinize
          girmiş olduğunuz adınız veya adresiniz gibi kişisel belirleme verileri
          bu amaç için kullanılmayacaktır. Çevirim içi hizmetlerimizde
          özelleştirilmiş reklamlar göstermek için daima izninizi isteyeceğiz.
          Bunu, çevirim içi hizmetlerimizi kullandığınız zaman ekranınızda
          beliren çerez ayarları vasıtası ile yaparız. Ayrıca, herhangi bir
          zamanda aynı ayarları kullanarak rızanızı geri çekebilirsiniz.
          <br />
          <br />
          Size açıklık sağlamak için, rızanızı vermiş olmasanız bile çevirim içi
          hizmetlerimizde yine de reklamlar görecek veya işiteceksiniz. Bu
          durumda bu reklamlar yalnızca daha az ilgili olacaktır çünkü bunlar
          olası ilgi alanlarınıza uyarlanmış olmayacaktır. Bu nedenle, uygun
          duruma getirilmiş reklamlar alıp almamayı seçmeyi size bırakıyoruz.
          <br />
          <br />
          Yeniden pazarlama çerezleri – Bu web sitesi, bu web sitesinde ziyaret
          ettiğiniz sayfalar temelinde ilgi duyabileceğiniz ürünlerin veya
          hizmetlerin çetelesini tutan çerezler kullanmaktadır. Bu çerezler
          sayesinde ve Google reklam ağının (Google Analytics) araçlarını
          kullanarak, Google reklam ağının parçası olan başka web sitelerinde
          size ilginizi çekebilecek ürünler ve hizmetler için reklamlar
          gösterebiliriz. Bu tekniğe “yeniden pazarlama” denmektedir. Bu
          çerezler hiç bir kişisel veri içermez.
          <br />
          <br />
          &nbsp;
        </p>
        <p>
          <strong>Çerezler ne kadar kalır?</strong>
        </p>
        <p>
          Çerezlerin varlığı temelinde, geçici ve kalıcı çerezler arasında bir
          fark gözetiriz.
          <br />
          <br />
          Geçici çerezler tarayıcınızda veya (mobil) uygulamanızda geçici
          şekilde depolanır. Yani tarayıcınızı veya (mobil) uygulamanızı
          kapattığınız anda bu çerezler silinir.
          <br />
          <br />
          Kalıcı çerezler, tarayıcınızı veya (mobil) uygulamanızı kapatmış
          olsanız bile bilgisayarınızda veya mobil cihazınızda kalır. Kalıcı
          çerezler sayesinde, web sitemizi veya (mobil) uygulamamızı daha sonra
          tekrar ziyaret ettiğinizde sizi tanıyabiliriz. Kalıcı çerezler,
          önceden tanımlanan bir son tarihe ulaşılıncaya kadar, yeni bir çerez
          yerleştirilinceye kadar veya tarayıcınızın, (mobil) uygulamanızın veya
          mobil cihazınızın ayarları vasıtası ile onları silmenize kadar
          cihazınızda kalır.
        </p>
        <p>
          Çereze göre saklama süresini aşağıdaki listede kontrol edebilirsiniz.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Çerezler nasıl devre dışı bırakılır?</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Firefox</strong>
        </p>
        <p>1. Firefoxu açın.</p>
        <p>
          2. Firefox penceresinin üst kısmında bulunan Firefox butonuna,
          ardından da Seçeneklere tıklayın.
        </p>
        <p>3. Gizlilik panelini seçin.</p>
        <p>
          4. Çerezleri ve site verilerini engelle (bazı siteler düzgün
          çalışmayabilir)yi işaretleyin.
        </p>
        <p>5. “Tamam”ı tıklayın.</p>
        <p>
          <strong>Internet Explorer</strong>
        </p>
        <p>1. Internet Explorerı açın.</p>
        <p>2. “Araçlar” menüsüne girin ve “İnternet Seçenekleri”ne tıklayın.</p>
        <p>
          3. Önce “Gizlilik” sekmesine, daha sonra “Ayarlar”a tıklayın, tüm
          çerezleri engellemek için kontrol çubuğunu yukarı kaydırın ve ”Tamam”ı
          tıklayın.
        </p>
        <p>
          <strong>Google Chrome</strong>
        </p>
        <p>1. Google Chromeu açın.</p>
        <p>2. Araçlar ikonunu seçin.</p>
        <p>3. “Ayarlar”ı tıklayın.</p>
        <p>4. Altta “Gelişmiş”i tıklayın.</p>
        <p>5. “Gizlilik ve güvenlikin altında “İçerik ayarları”nı tıklayın.</p>
        <p>
          6. Tüm çerezler ve site verilerinin altında Tümünü kaldırı
          tıklayın.
        </p>
        <p>
          <strong>Safari</strong>
        </p>
        <p>1. Safariyi açın.</p>
        <p>2. “Tercihler”i seçin ve “Gizlilik“ öğesini tıklayın.</p>
        <p>
          3. “Çerezleri engelle” bölümünde, Safarinin çerezleri kabul edip
          etmeyeceğini seçebilirsiniz. Seçeneklerin açıklamasını görmek için,
          yardım butonuna tıklayın (soru işareti)
        </p>
        <p>
          4. Bilgisayarınızda hangi sitelerin çerezlerinin saklandığını görmek
          istiyorsanız, Detayları tıklayın.
        </p>
        <p>&nbsp;</p>
        <p>
          Maltbahis web sitesine danışmak için bilgisayarınızda, tabletinizde
          veya mobil telefonunuzda çerezleri etkin duruma getirmeniz tavsiye
          edilir. Çerezler etkin durumda olmadan web sitemize sorunsuz bir
          ziyaret yapılmasını garanti edemeyebiliriz. Ama yine de Maltbahis’ten
          çerezleri kısıtlamayı, engellemeyi veya silmeyi tercih etmeniz halinde
          tarayıcı ayarlarınızı yukarıda açıklanan şekilde yönetebilirsiniz
        </p>
      </div>
    </div>
  );
};

export { CookieNoticeModal };
