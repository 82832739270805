import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommunicationModal } from "../footer/components/ContentModals/CommunicationModal/CommunicationModal";
import { CompanyInfoModal } from "../footer/components/ContentModals/CompanyInfoModal/CompanyInfoModal";
import { CookieNoticeModal } from "../footer/components/ContentModals/CookieNoticeModal/CookieNoticeModal";
import { PrivacyPolicyModal } from "../footer/components/ContentModals/PrivacyPolicyModal/PrivacyPolicyModal";
import { ResponsibleActingModal } from "../footer/components/ContentModals/ResponsibleActingModal/ResponsibleActingModal";
import { TermsConditionsModal } from "../footer/components/ContentModals/TermsConditionsModal/TermsConditionsModal";

import "./FooterModal.scss";

const FooterModal = () => {
  const [searchParams] = useSearchParams();
  const helpPageContent = searchParams.get("helpPageContent") || "";
  const navigate = useNavigate();

  const handleCloseWindow = () => {
    navigate("", {
      replace: true,
    });
  };
  return (
    <div className="popup-holder-bc windowed  content-manager ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc">
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleCloseWindow}
          />
          {helpPageContent === "30749" ? <CompanyInfoModal /> : null}
          {helpPageContent === "27091" ? <CommunicationModal /> : null}
          {helpPageContent === "27089" ? <ResponsibleActingModal /> : null}
          {helpPageContent === "27092" ? <TermsConditionsModal /> : null}
          {helpPageContent === "30750" ? <PrivacyPolicyModal /> : null}
          {helpPageContent === "30752" ? <CookieNoticeModal /> : null}
        </div>
      </div>
    </div>
  );
};

export { FooterModal };
