import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { setModalLogin } from "../../../../store/action/modals/setmodallogin";
import { setModalRegister } from "../../../../store/action/modals/setmodalregister";
import { AuthForm } from "./AuthForm/AuthForm";

import logo from "../../../../accets/images/png/logo.png";

import "./AuthModal.scss";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";
import { Link } from "react-router-dom";

interface IAuthModal {
  handleClosePopap: () => void;
}

const AuthModal = ({ handleClosePopap }: IAuthModal) => {
  const dispatch = useDispatch();
  const handleOpenRegisterForm = () => {
    dispatch(setModalLogin(false));
    dispatch(setModalRegister(true));
  };
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div className="entrance-popup-bc sign-in">
      <div className="e-p-content-holder-bc">
        <div className="e-p-content-bc">
          <div className="e-p-header-bc">
            <Link className="popup-t-logo-w-bc" to={`/${language}/`}>
              <img className="hdr-logo-bc" src={logo} alt="" />
            </Link>
            <div className="e-p-sections-bc">
              <div className="e-p-section-item-bc">
                <button
                  className="btn s-small register "
                  type="button"
                  title="Register"
                  onClick={handleOpenRegisterForm}
                >
                  <span>{t("admin.Register")}</span>
                </button>
              </div>
            </div>
          </div>
          <div className="e-p-body-bc">
            <AuthForm handleClosePopap={handleClosePopap} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { AuthModal };
