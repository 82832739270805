import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BalanceAccount } from "./components/Accounts/BalanceAccount/BalanceAccount";
import { BetBuilderHistoryAccount } from "./components/Accounts/BetBuilderHistoryAccount/BetBuilderHistoryAccount";
import { BetHistoryAccount } from "./components/Accounts/BetHistoryAccount/BetHistoryAccount";
import { LoyaltyPointsAccount } from "./components/Accounts/LoyaltyPointsAccount/LoyaltyPointsAccount";
import { MainAccount } from "./components/Accounts/MainAccount/MainAccount";
import { MessagesAccount } from "./components/Accounts/MessagesAccount/MessagesAccount";
import { ProfileAccout } from "./components/Accounts/ProfileAccout/ProfileAccout";

import "./UserAdmin.scss";

const UserAdmin = () => {
  const [searchParams] = useSearchParams();
  const account = searchParams.get("account") || "";
  const [selectedGroup, setSelectedGroup] = useState("");
  return (
    <div
      className="overlay-sliding-wrapper-bc user-profile-container "
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div
        className="overlay-sliding-w-c-content-slider-bc"
        data-scroll-lock-scrollable=""
      >
        <div className="u-i-p-c-body-bc">
          {account === "" ? (
            <MainAccount
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          ) : null}
          {account === "balance" ? <BalanceAccount /> : null}
          {account === "bet-builder-history" ? (
            <BetBuilderHistoryAccount />
          ) : null}
          {account === "bonuses" ? <LoyaltyPointsAccount /> : null}
          {account === "profile" ? <ProfileAccout /> : null}
          {account === "history" ? <BetHistoryAccount /> : null}
          {account === "messages" ? <MessagesAccount /> : null}
        </div>
      </div>
    </div>
  );
};

export { UserAdmin };
