import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./LoyaltyPoints.scss";

const LoyaltyPoints = () => {
  return (
    <Link
      className="u-i-p-a-loyaltyPoint-bc"
      to="/?profile=open&account=bonuses&page=loyalty-points"
    >
      <img
        src="https://cmsbetconstruct.com/content/images/loyalty_points/bronze.png"
        className="loyaltyPointsCurrentPlanMedal-bc"
        alt=""
      />
      <p className="ellipsis">{t("admin.LoyaltyPoints")}</p>
      <span>0 / 0</span>
    </Link>
  );
};

export { LoyaltyPoints };
