import React from "react";

import "./CompanyInfoModal.scss";

const CompanyInfoModal = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">Firma Bilgileri</span>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <p style={{ textAlign: "center" }}>Maltbahis.com</p>
        <p style={{ textAlign: "center" }}>
          Uluslararası online oyun alanında 10 yılı aşkın tecrübeye sahip olan
          profesyonel ekibiyle müşterilerine en iyi hizmeti sunmayı amaçlayan
          bir bahis ve eğlence platformudur.
          <br />
          Bahis bölümünde en iyi oranlarla 50’nin üzerinde spor türü; canlı
          bahis bölümünde her ay 40.000’den fazla seçenek; gerçek krupiyelerle
          oynayabileceğiniz canlı casino; birçok masa oyunu, video slot, video
          poker ve mini oyunları bir arada bulabileceğiniz casino; tüm bunların
          yanı sıra sanal oyunlar, bedava bahis ve çok daha fazla oyunun yer
          aldığı Maltbahis.com hayatınıza yeni bir eğlence anlayışı getirmeyi
          misyon edinen bir kurumdur.
        </p>
        <p style={{ textAlign: "center" }}>
          © 2022 K Softworks NV - tüm hakları saklıdır.
        </p>
        <p style={{ textAlign: "center" }}>
          K Softworks NV, Perseusweg 27/A Curacao adresine kayıtlı bir
          şirkettir.
        </p>
        <p style={{ textAlign: "center" }}>
          Şans oyunları komisyonu tarafından verilmiş çevrimiçi bahis /casino
          oyunları için gerekli olan 8048/JAZ 2016-081 numaralı lisansa
          sahiptir.
        </p>
      </div>
    </div>
  );
};

export { CompanyInfoModal };
