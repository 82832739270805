import React from "react";
import { Link } from "react-router-dom";

import './TelegramSlot.scss'

const TelegramSlot = () => {
    return <div
    className="hm-row-bc has-slider"
    style={{ gridTemplateColumns: "12fr" }}
  >
    <div className="slider-bc  hide-shadow">
      <div className="carousel">
        <div className="carousel-viewport">
          <div
            className="carousel-container"
            style={{ transform: "translate3d(0%, 0px, 0px)" }}
          >
            <div
              className="carousel-slide active-slide"
              style={{ width: "100%" }}
            >
              <div className="sdr-item-holder-bc">
                <Link
                  to="https://t.me/maltbahistelegram"
                  target="_self"
                  className="sdr-item-bc"
                >
                  <img
                    src="https://cmsbetconstruct.com/storage/medias/maltbahis/content_1873892_e2a77fc62cf0ca795a1e3f22e5312abc.webp"
                    loading="lazy"
                    className="sdr-image-bc"
                    alt=""
                    title="Telegram"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export {TelegramSlot}