import React, { useState } from "react";

import "./BonusHistoryPage.scss";

import load from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const BonusHistoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const handleFilterOpen = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleShow = () => {
    setLoading(true);
    setFilterOpen(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content"
      data-scroll-lock-scrollable=""
    >
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${filterOpen ? "active" : ""}`}
          onClick={handleFilterOpen}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">{t("admin.Filter")}</p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc select has-icon valid filled">
                  <label className="form-control-label-bc inputs">
                    <select
                      className="form-control-select-bc active"
                      name="product"
                    >
                      <option value="Sport">{t("admin.Sport")}</option>
                      <option value="Casino">{t("admin.Casino")}</option>
                    </select>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Category")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc select has-icon valid filled">
                  <label className="form-control-label-bc inputs">
                    <select
                      className="form-control-select-bc active"
                      name="period"
                    >
                      <option value={1}>1 {t("admin.hours")}</option>
                      <option value={2}>2 {t("admin.hours")}</option>
                      <option value={3}>3 {t("admin.hours")}</option>
                      <option value={6}>6 {t("admin.hours")}</option>
                      <option value={12}>12 {t("admin.hours")}</option>
                      <option value={24}>24 {t("admin.hours")}</option>
                      <option value={48}>48 {t("admin.hours")}</option>
                      <option value={72}>72 {t("admin.hours")}</option>
                      <option value="">{t("admin.Custom")}</option>
                    </select>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                    {t("admin.Period")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color "
                  type="submit"
                  title="Show"
                  onClick={handleShow}
                >
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="bc-loader medium ">
          <div className="bc-loader-contain">
            <img src={load} alt="" />
          </div>
        </div>
      ) : (
        <p className="empty-b-text-v-bc">
          {t("admin.Therearenodatafortheselectedtimeperiod")}
        </p>
      )}
    </div>
  );
};

export { BonusHistoryPage };
