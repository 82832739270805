import { t } from "i18next";
import React from "react";

import "./CasinoBonusPage.scss";

const CasinoBonusPage = () => {
  return (
    <div
      className="sportBonusWrapper-bc casinoBonus_ProgressBar"
      data-scroll-lock-scrollable=""
    >
      <p className="empty-b-text-v-bc">
        {t("admin.Therearenobonusesforselectedtype")}
      </p>
    </div>
  );
};

export { CasinoBonusPage };
