import { t } from "i18next";
import React, { useState } from "react";

import "./PromoCodePage.scss";

const PromoCodePage = () => {
  const [inputValue, setInputValue] = useState("");
  const [inputOpen, setInputOpen] = useState(false);
  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  const handleFocus = () => {
    setInputOpen(true);
  };
  const handleBlur = () => {
    if (inputValue.length > 0) {
      setInputOpen(true);
    } else {
      setInputOpen(false);
    }
  };
  return (
    <div className="promoCodeWrapper-bc ">
      <form>
        <div className="u-i-p-control-item-holder-bc">
          <div
            className={`form-control-bc default ${inputOpen ? "focused" : ""}`}
          >
            <label className="form-control-label-bc inputs">
              <input
                type="text"
                className="form-control-input-bc"
                name="promoCode"
                value={inputValue}
                onChange={handleChangeValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <i className="form-control-input-stroke-bc" />
              <span className="form-control-title-bc ellipsis">{t("admin.Promocode")}</span>
            </label>
          </div>
        </div>
        <div className="u-i-p-c-footer-bc">
          <button
            className="btn a-color big-btn "
            type="submit"
            title="Apply"
            disabled={true}
          >
            <span>{t("admin.Apply")}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export { PromoCodePage };
