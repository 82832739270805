import { t } from "i18next";
import React from "react";
import { GameProviderRequest } from "../../../common/types/Casino/GameProvider";

import "./LiveCasinoProviders.scss";

interface ILiveCasinoProviders {
  selectedProviders: number[];
  setSelectedProviders: React.Dispatch<React.SetStateAction<number[]>>;
  providersList: GameProviderRequest | undefined;
}

const LiveCasinoProviders = ({
  selectedProviders,
  setSelectedProviders,
  providersList,
}: ILiveCasinoProviders) => {
  const handleSelectItem = (item: number) => {
    setSelectedProviders((prevState) => {
      if (prevState.includes(item)) {
        return prevState.filter((existingId: number) => existingId !== item);
      } else {
        return [...prevState, item];
      }
    });
  };
  return (
    <div className="casino-game-tabs live-casino-no-categories">
      <div
        className="horizontal-sl-tab-container"
        data-scroll-lock-scrollable=""
      >
        <div className="horizontal-sl-tab ">
          <div
            className="horizontal-sl-tab-bc custom-font-size selected-underline active "
            data-count=""
            data-badge=""
            data-id="All"
          >
            {t("admin.All")}
          </div>
          {providersList
            ? Object.keys(providersList).map((item) => {
                return providersList[item]?.map((item) => (
                  <div
                    className={`horizontal-sl-tab-bc custom-font-size selected-underline ${
                      selectedProviders.includes(item.id) ? "selected" : ""
                    }`}
                    data-badge=""
                    key={item.id}
                    onClick={() => handleSelectItem(item.id)}
                  >
                    {item.name}
                  </div>
                ));
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export { LiveCasinoProviders };
