import React from "react";
import { Provider } from "react-redux";
import "./App.scss";
import { Navigation } from "./navigation/Navigation";
import { useEffect } from "react";
import { store } from "./store";


function App() {
  useEffect(() => {
    document.body.setAttribute("dir", "ltr");
  }, []);

  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;
