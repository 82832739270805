import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./TermsConditions.scss";

const TermsConditions = () => {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive((prev) => !prev);
  };
  return (
    <div className={`ftr-m-nav-l-item-bc ${active ? "active" : ""}`}>
      <div className="ftr-m-nav-title-bc" onClick={handleActive}>
        <span className="ftr-m-nav-name-bc ellipsis custom-font-light">Hüküm ve Koşullar</span>
        <i className="ftr-m-nav-icon-bc bc-i-small-arrow-down" />
      </div>
      <div className="ftr-m-nav-l-item-hidden-bc">
        <Link
          target="_self"
          className="ftr-m-nav-link-bc ellipsis"
          to={{
            search: "?helpPageContent=27092",
          }}
        >
          <span className="ellipsis">Hüküm ve Koşullar</span>
        </Link>
      </div>
    </div>
  );
};

export { TermsConditions };
