import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userDeposit } from "../../../../../../../../common/types/AdminInfo/userDeposit";
import { AdminPanel } from "../../../../../../../../services";

import "./PayForm.scss";

interface IPayForm {
  selectedPaymentMethod: userDeposit | undefined;
}

const PayForm = ({ selectedPaymentMethod }: IPayForm) => {
  const [inputError, setInputError] = useState(false);
  const [inputOpen, setInputOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const location = useLocation();
  const nanvigate = useNavigate();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Оставить только цифры
    if (
      Number(numericValue) > Number(selectedPaymentMethod?.currencies[0]?.min)
    ) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    setInputValue(numericValue);
  };

  const handleFocus = () => {
    setInputOpen(true);
  };

  const handleBlur = () => {
    if (inputValue.length > 0) {
      setInputOpen(true);
      setInputError(false);
    } else {
      setInputOpen(false);
      setInputError(true);
    }
  };
  useEffect(() => {
    if (
      Number(inputValue) > Number(selectedPaymentMethod?.currencies[0]?.min)
    ) {
      setInputError(false);
    } else {
      setInputError(true);
    }
  }, []);

  const handleGoBack = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("selectedMethod");
    nanvigate({
      search: searchParams.toString(),
    });
  };

  const handleMoveToPay = () => {
    async function getPaymentUrl() {
      const res = await AdminPanel.userPay({
        data: {
          amount: Number(inputValue) * 100,
          currency: "TRY",
          payment_aggregator_id: selectedPaymentMethod?.payment_aggregator_id,
          service_id: selectedPaymentMethod?.service_id,
        },
        params: "",
      });
      window.open(res.data.redirectUrl, "_blank");
    }
    async function getPaymentManualUrl() {
      await AdminPanel.userPayManual({
        data: {
          amount: Number(inputValue) * 100,
          currency: "TRY",
          payment_aggregator_id: selectedPaymentMethod?.payment_aggregator_id,
          service_id: selectedPaymentMethod?.service_id,
        },
        params: "",
      });
      alert("succesfull pay");
    }
    if (selectedPaymentMethod?.service_id === "77777") {
      getPaymentManualUrl();
    } else {
      getPaymentUrl();
    }
  };

  return (
    <div
      className="overlay-sliding-wrapper-bc  "
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div
        className="overlay-sliding-w-c-content-slider-bc"
        data-scroll-lock-scrollable=""
      >
        <div className="back-nav-bc" onClick={handleGoBack}>
          <i className="back-nav-icon-bc bc-i-round-arrow-left" />
          <span className="back-nav-title-bc ellipsis">
            {selectedPaymentMethod
              ? selectedPaymentMethod.payment_name || selectedPaymentMethod.name
              : ""}
          </span>
        </div>
        <div className="payment-info-bc" data-scroll-lock-scrollable="">
          <div className="description-c-row-bc HedefHavale">
            <div className="description-c-row-column-bc pay-logo">
              <img
                // src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10795.png"
                alt=""
              />
            </div>
            <div className="description-c-row-column-bc texts">
              <div className="description-c-row-c-title-bc has-border">
                <div className="description-c-r-c-t-column-bc">
                  <span className="description-title ellipsis">
                    {t("admin.Fee")}: Free
                  </span>
                </div>
                <div className="description-c-r-c-t-column-bc">
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className="description-c-row-c-title-bc ">
                <div className="description-c-r-c-t-column-bc">
                  <span className="description-title  ellipsis" title="Min">
                    {t("admin.Min")}
                  </span>
                  <span className="description-value">
                    {selectedPaymentMethod
                      ? selectedPaymentMethod.currencies[0].min
                      : ""}{" "}
                    ₺
                  </span>
                </div>
                <div className="description-c-r-c-t-column-bc">
                  <span className="description-title  ellipsis" title="Max">
                    {t("admin.Max")}
                  </span>
                  <span className="description-value">
                    {selectedPaymentMethod
                      ? selectedPaymentMethod.currencies[0].max
                      : ""}{" "}
                    ₺
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="deposit-method-description HedefHavale">
            {selectedPaymentMethod
              ? selectedPaymentMethod.payment_name || selectedPaymentMethod.name
              : ""}
          </div>
          <div className="withdraw-form-l-bc">
            <>
              <div className="u-i-p-control-item-holder-bc">
                <div
                  className={`form-control-bc default ${
                    inputOpen ? "focused" : ""
                  } ${inputError ? "invalid" : "valid"}`}
                >
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      inputMode="decimal"
                      className="form-control-input-bc"
                      name="amount"
                      value={inputValue}
                      onChange={handleChangeInput}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Amount")}
                    </span>
                  </label>
                  {inputError ? (
                    <div className="form-control-message-holder-bc">
                      <span className="form-control-message-bc">
                        {t("admin.Thisfieldisrequired")}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color deposit "
                  type="submit"
                  title="DEPOSIT"
                  disabled={inputError}
                  onClick={handleMoveToPay}
                >
                  <span>{t("admin.Deposit")}</span>
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export { PayForm };
