import React, { useState } from "react";

import "./FreeSpinPage.scss";

import load from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";

const FreeSpinPage = () => {
  const [spinType, setSpinType] = useState("new");
  const [loading, setLoading] = useState(false);
  const handleChooseSpin = (item: string) => {
    setSpinType(item);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  return (
    <div className="sportBonusWrapper-bc" data-scroll-lock-scrollable="">
      <div className="second-tabs-bc">
        <div
          className={`tab-bc selected-underline ${
            spinType === "new" ? "active" : ""
          }`}
          title=""
          onClick={() => handleChooseSpin("new")}
        >
          {t("admin.New")}
        </div>
        <div
          className={`tab-bc selected-underline ${
            spinType === "activated" ? "active" : ""
          }`}
          title=""
          onClick={() => handleChooseSpin("activated")}
        >
          {t("admin.Activated")}
        </div>
      </div>
      {loading ? (
        <div className="bc-loader medium ">
          <div className="bc-loader-contain">
            <img src={load} alt="" />
          </div>
        </div>
      ) : (
        <p className="empty-b-text-v-bc">
          {t("admin.Therearenobonusesforselectedtype")}
        </p>
      )}
    </div>
  );
};

export { FreeSpinPage };
