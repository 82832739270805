import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./LoyaltyPointsAccount.scss";
import { LoyaltyPointsNav } from "./navigation/LoyaltyPointsNav";
import { BonusHistoryPage } from "./pages/BonusHistoryPage/BonusHistoryPage";
import { CasinoBonusPage } from "./pages/CasinoBonusPage/CasinoBonusPage";
import { ExchangeShopPage } from "./pages/ExchangeShopPage/ExchangeShopPage";
import { FreeSpinPage } from "./pages/FreeSpinPage/FreeSpinPage";
import { LoyaltyPointsPage } from "./pages/LoyaltyPointsPage/LoyaltyPointsPage";
import { PromoCodePage } from "./pages/PromoCodePage/PromoCodePage";
import { SportBonusPage } from "./pages/SportBonusPage/SportBonusPage";

const LoyaltyPointsAccount = () => {
  const location = useLocation();
  const nanvigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const handleGoBack = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("account");
    searchParams.delete("page");
    nanvigate({
      search: searchParams.toString(),
    });
  };
  return (
    <div className="u-i-p-c-body-bc">
      <div className="back-nav-bc" onClick={handleGoBack}>
        <i className="back-nav-icon-bc bc-i-round-arrow-left" />
        <span className="back-nav-title-bc ellipsis">{t("admin.Bonuses")}</span>
      </div>
      <LoyaltyPointsNav page={page} />
      {page === "loyalty-points" ? <LoyaltyPointsPage /> : null}
      {page === "exchange-shop" ? <ExchangeShopPage /> : null}
      {page === "sport" ? <SportBonusPage /> : null}
      {page === "bonus-history" ? <BonusHistoryPage /> : null}
      {page === "casino" ? <CasinoBonusPage /> : null}
      {page === "promo-code" ? <PromoCodePage /> : null}
      {page === "casino-free-spins" ? <FreeSpinPage /> : null}
    </div>
  );
};

export { LoyaltyPointsAccount };
