type modalsReducerType = {
  modalRegisterOpen: boolean;
  modalAuthOpen: boolean;
  popapOpen: boolean;
};

const initialState: modalsReducerType = {
  modalRegisterOpen: false,
  modalAuthOpen: false,
  popapOpen: false,
};

type authAction = {
  type: string;
  payload: unknown;
};

const modalsReducer = (state = initialState, action: authAction) => {
  switch (action.type) {
    case "SET_MODAL_LOGIN":
      return { ...state, modalAuthOpen: action.payload };
    case "SET_MODAL_REGISTER":
      return { ...state, modalRegisterOpen: action.payload };
    case "SET_MODAL_POPAP":
      return { ...state, popapOpen: action.payload };
    default:
      return state;
  }
};

export { modalsReducer };

export type { modalsReducerType };
