import item1 from "./accets/item1.webp"
import item2 from "./accets/item2.webp";
import item3 from "./accets/item3.webp";
import item4 from "./accets/item4.webp";

type mockCouruselLiveType = {
  id: number;
  img: string;
  title: string;
};

const mockCouruselLive: mockCouruselLiveType[] = [
  {
    id: 1,
    img: item1,
    title: "%10 ek",
  },
  {
    id: 2,
    img: item2,
    title: "10",
  },
  {
    id: 3,
    img: item3,
    title: "Drop",
  },
  {
    id: 4,
    img: item4,
    title: "Sınırsız fs",
  },
];

export { mockCouruselLive };
export type { mockCouruselLiveType };
