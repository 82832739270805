type Ilanguage = {
  languageShort: string;
  languageAbs: string;
  languageFlag: string;
  languageName: string;
};

type settingsState = {
  oddsFormat: string;
  language: Ilanguage;
  timeFormat: string;
};

const initialState: settingsState = {
  oddsFormat: "Decimal",
  language: {
    languageShort: "tr",
    languageAbs: "TRY",
    languageFlag: "turkey",
    languageName: "Türkçe",
  },
  timeFormat: "24h",
};

type authAction = {
  type: string;
  payload: unknown;
};

const settingsReducer = (state = initialState, action: authAction) => {
  switch (action.type) {
    case "SET_ODDS_FORMAT":
      return { ...state, oddsFormat: action.payload };
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "SET_TIME_FORMAT":
      return { ...state, timeFormat: action.payload };
    default:
      return state;
  }
};

export { settingsReducer };

export type { settingsState, Ilanguage };
