import { t } from "i18next";
import React, { useState } from "react";

import "./TransactionHistoryPage.scss";

const TransactionHistoryPage = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const handleMoveSelect = () => {
    setSelectOpen((prev) => !prev);
  };
  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content"
      data-scroll-lock-scrollable=""
    >
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${selectOpen ? "active" : ""}`}
          onClick={handleMoveSelect}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">Filter</p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc select has-icon valid filled">
                  <label className="form-control-label-bc inputs">
                    <select
                      className="form-control-select-bc active"
                      name="type"
                    >
                      <option value={999}>{t("admin.All")}</option>
                      <option value={301}>{t("admin.Correctio nup")}</option>
                      <option value={302}>{t("admin.Correction down")}</option>
                      <option value={87}>{t("admin.Casino CashBack Bonus")}</option>
                      <option value={90}>{t("admin.Casino Bet Winning")}</option>
                      <option value={80}>{t("admin.Loyalty Point Exchange")}</option>
                      <option value={309}>{t("admin.CashBack Correction")}</option>
                      <option value={131}>{t("admin.Casino")}</option>
                      <option value={16}>{t("admin.Decreasing the winning")}</option>
                      <option value={3}>{t("admin.Deposit")}</option>
                      <option value={6}>{t("admin.Deposit request")}</option>
                      <option value={1}>{t("admin.Withdrawal request")}</option>
                      <option value={2}>{t("admin.Withdrawal request Payment")}</option>
                      <option value={7}>{t("admin.Deposit request payment")}</option>
                      <option value={14}>{t("admin.Bet Cashout")}</option>
                      <option value={15}>{t("admin.Increasing the winning")}</option>
                      <option value={8}>{t("admin.Rejected withdrawal request")}</option>
                      <option value={10}>{t("admin.Bet")}</option>
                    </select>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                    {t("admin.TransactionType")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc">
                <div className="form-control-bc select has-icon valid filled">
                  <label className="form-control-label-bc inputs">
                    <select
                      className="form-control-select-bc active"
                      name="product"
                    >
                      <option value="">{t("admin.Main")}</option>
                      <option value="Casino">{t("admin.Casino")}</option>
                    </select>
                    <i className="form-control-icon-bc bc-i-small-arrow-down" />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                    {t("admin.Category")}
                    </span>
                  </label>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
                <div className="react-datepicker-wrapper">
                  <div className="react-datepicker__input-container">
                    <div className="form-control-bc default filled valid filled">
                      <label className="form-control-label-bc inputs">
                        <input
                          className="form-control-input-bc "
                          type="text"
                          placeholder="From"
                          defaultValue="05.06.2023"
                        />
                        <i className="form-control-input-stroke-bc" />
                        <span className="form-control-title-bc ellipsis">
                        {t("admin.Range")}
                        </span>
                        <i className="dropdownIcon-bc bc-i-datepicker" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-i-p-control-item-holder-bc halfPartFilterSection">
                <div className="react-datepicker-wrapper">
                  <div className="react-datepicker__input-container">
                    <div className="form-control-bc default filled valid filled">
                      <label className="form-control-label-bc inputs">
                        <input
                          className="form-control-input-bc "
                          type="text"
                          placeholder="To"
                          defaultValue="05.06.2023"
                        />
                        <i className="form-control-input-stroke-bc" />
                        <span className="form-control-title-bc ellipsis" />
                        <i className="dropdownIcon-bc bc-i-datepicker" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="u-i-p-c-footer-bc">
                <button className="btn a-color " type="submit" title="Show">
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="empty-b-text-v-bc">
      {t("admin.Therearenodatafortheselectedtimeperiod")}
      </p>
    </div>
  );
};

export { TransactionHistoryPage };
