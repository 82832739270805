import React from "react";

import "./TermsConditionsModal.scss";

const TermsConditionsModal = () => {
  return (
    <div className="help-popup">
      <div className="help-popup-title">
        <span className="help-popup-title-text ellipsis">
          Hüküm ve Koşullar
        </span>
      </div>
      <div className="content" data-scroll-lock-scrollable="">
        <div className="row">
          <div className="col-xs-12 sgl-content">
            <div className="breadcrumbs">&nbsp;</div>
            <div className="col-xs-12 content">
              <div className="col-xs-12 content-row">
                <p>
                  <strong>Hüküm ve Koşullar</strong>
                </p>
                <p>&nbsp;</p>
                <p>
                  K Softworks N.V. www.maltbahis.com web sitesi üzerinden şans
                  oyunları sunmaktadır.
                </p>
                &nbsp;
                <p>
                  K Softworks N.V, Çuracao tarafından verilmiş işletme lisansı
                  aracılığıyla yasal olarak çevrimiçi şans oyunları
                  sunabilmektedir.
                </p>
                &nbsp;
                <p>Bu oyunlara katılım, mevcut sözleşmeye tabidir.</p>
                &nbsp;
                <p>
                  Lütfen K Softworks N.V ve kullanıcı arasında bağlayıcı bir
                  anlaşma oluşturan Genel Kullanım Hüküm ve Koşulları’nı
                  dikkatli bir şekilde okuyunuz. Kayıt sayfasındaki Kullanım
                  Hüküm ve Koşullarını kabul ediyoruma tıklayarak, mevcut hüküm
                  ve koşulları okuduğunuzu, kabul ettiğinizi ve bunlara bağlı
                  olduğunuzu, kurum kurallarını ve bunlara uymayı, Yazılımın ve
                  şans oyunlarının kurallarını ve belirli talimatlarını,
                  gizlilik politikamızı, para çekme politikamızı ve oyun
                  hakkaniyet politikamızın talimatlarını ve bunların zamanla
                  düzenlenebileceğini her koşulda onaylamış olursunuz.
                  Kullanıcı, Site’nin Genel Kullanım Hüküm ve Koşulları ile aynı
                  fikirde olmaması durumunda, Kullanım Hüküm ve Koşullarını
                  kabul ediyoruma tıklamamalı ve www.Maltbahis.com&nbsp;web
                  sitesini görüntülemeyi bırakmalıdır. Bu tür Kullanıcılar
                  ayrıca Yazılımı indirmekten, kurmaktan veya herhangi bir
                  şekilde kullanmaktan kaçınmalı ve Yazılımın tüm izlerini
                  bilgisayarlarından silmelilerdir.
                </p>
                &nbsp;
                <p>
                  Hüküm ve Koşulların kabulü doğrultusunda hak ve
                  yükümlülükleriniz ile ilgili herhangi bir şüpheniz varsa,
                  lütfen yargı alanınızdaki bir avukattan bilgi alın. Kayıt
                  işleminden önce, yargı alanınızdaki mevcut yasaların farkında
                  olmanız önemlidir. “Maltbahis yerel hukuki mevzuata ve yargı
                  çevresine dair herhangi bir sorumluluk kabul etmez.”
                </p>
                <div className="block">
                  <div className="answer inner">
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TermsConditionsModal };
