import { t } from "i18next";
import React from "react";
import "./FuturedGamesSlot.scss";

const FuturedGamesSlot = () => {
  return (
    <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
      <div className="ftr-g-content-bc ">
        <div className="page-view-more">
          <h3 className="view-title-more ellipsis">
            {t("casino.Featuredgames")}
          </h3>
        </div>
        <p className="empty-b-text-v-bc">
          {t("casino.Thearenofeaturedgamesatthemoment")}
        </p>
      </div>
    </div>
  );
};

export { FuturedGamesSlot };
