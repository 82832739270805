import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./AboutUs.scss";

const AboutUs = () => {
  const [active, setActive] = useState(true);
  const handleActive = () => {
    setActive((prev) => !prev);
  };
  return (
    <div className={`ftr-m-nav-l-item-bc ${active ? "active" : ""}`}>
      <div className="ftr-m-nav-title-bc" onClick={handleActive}>
        <span className="ftr-m-nav-name-bc ellipsis custom-font-light">Hakkımızda</span>
        <i className="ftr-m-nav-icon-bc bc-i-small-arrow-down" />
      </div>
      <div className="ftr-m-nav-l-item-hidden-bc">
        <Link
          target="_self"
          className="ftr-m-nav-link-bc ellipsis"
          to={{
            search: "?helpPageContent=30749",
          }}
        >
          <span className="ellipsis">Firma Bilgileri</span>
        </Link>
        <Link
          target="_self"
          className="ftr-m-nav-link-bc ellipsis"
          to={{
            search: "?helpPageContent=27091",
          }}
        >
          <span className="ellipsis">İletişim</span>
        </Link>
        <Link
          target="_self"
          className="ftr-m-nav-link-bc ellipsis"
          to={{
            search: "?helpPageContent=27089",
          }}
        >
          <span className="ellipsis">Sorumlu Oyunculuk</span>
        </Link>
      </div>
    </div>
  );
};

export { AboutUs };
