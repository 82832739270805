import React from "react";
import { Link } from "react-router-dom";
import { AboutUs } from "./components/aboutUs/AboutUs";
import { FooterSetteings } from "./components/footerSetteings/FooterSetteings";
import { FooterSecurity } from "./components/foterSecurity/FooterSecurity";
import { TermsConditions } from "./components/termsConditions/TermsConditions";

import "./Footer.scss";

const Footer = () => {
  return (
    <div className="layout-footer-holder-bc">
      <div className="footer-bc">
        <FooterSetteings />
        <AboutUs />
        <TermsConditions />
        <FooterSecurity />
        <div
          title="DESTEK İLE İLETİŞİME GEÇİN"
          className="live-chat-adviser-bc"
        >
          <i className="bc-i-live-chat" />
          <span className="ellipsis">DESTEK İLE İLETİŞİME GEÇİN</span>
        </div>
        <div className="ftr-social-link-row-bc">
          <span className="ftr-age-bc">18+</span>
          <div className="ftr-social-link-row-column-bc">
            <Link
              to="https://twitter.com/sosyalmalt"
              target="_blank"
              className="ftr-social-link-c-item-bc"
              rel="noreferrer"
            >
              <i className="social-icon bc-i-twitter" />
            </Link>
            <Link
              to="https://t.me/maltbahistelegram"
              target="_blank"
              className="ftr-social-link-c-item-bc"
              rel="noreferrer"
            >
              <i className="social-icon bc-i-telegram" />
            </Link>
          </div>
        </div>
        <div className="ftr-partners-row-bc">
          <div className="ftr-partners-row-inner-bc ">
            <Link
              to="https://android.nonamens.com/casino/casino-maltbahis.apk"
              target="_blank"
              className=""
              rel="noreferrer"
            >
              <img
                className="ftr-partners-r-img"
                loading="lazy"
                src="https://cmsbetconstruct.com/content/images/29254551737a317c3c510123910bdfe0_1873892_media.png"
                alt=""
              />
            </Link>
            <Link
              to="https://android.nonamens.com/sportsbook/sportsbook-maltbahis.apk"
              target="_blank"
              className=""
              rel="noreferrer"
            >
              <img
                className="ftr-partners-r-img"
                loading="lazy"
                src="https://cmsbetconstruct.com/content/images/b35b511a9714e701df9a1f69ca39275c_1873892_media.png"
                alt=""
              />
            </Link>
          </div>
          <div className="ftr-partners-row-inner-bc ">
            <div
              id="apg-seal-container"
              data-apg-seal-id="965dc6d2-26ec-4e34-b4dc-c9831d7a20e9"
              data-apg-image-size={128}
              data-apg-image-type="basic-small"
            >
              <div
                style={{
                  display: "block",
                  position: "relative",
                  overflow: "hidden",
                  maxWidth: 128,
                  minWidth: 32,
                  backgroundImage:
                    "url(https://965dc6d2-26ec-4e34-b4dc-c9831d7a20e9.snippet.antillephone.com/54f396e0-b046-49b1-9cb3-0c69281d7ea9-beacon.png)",
                }}
              >
                <Link
                  target="_blank"
                  rel="nonoopener noreferrer"
                  to="https://validator.antillephone.com/validate?domain=m.maltbahis286.com&seal_id=2bee807cd72da8a8805c62d49a5c434a9f1d9baf416dcef0271604651c2c4fb6910512fb39a3047754c26525a37b4764&stamp=0c468329478d2dcdef39a76a8986b7be"
                >
                  <img
                    alt=""
                    style={{ width: "100%", height: "auto" }}
                    src="https://965dc6d2-26ec-4e34-b4dc-c9831d7a20e9.snippet.antillephone.com/sealassets/0c468329478d2dcdef39a76a8986b7be-m.maltbahis286.com-2bee807cd72da8a8805c62d49a5c434a9f1d9baf416dcef0271604651c2c4fb6910512fb39a3047754c26525a37b4764-c2VhbC5wbmc%3D?status=valid"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="ftr-partners-row-inner-bc ">
            <p style={{ textAlign: "center" }} className="custom-footer-text">
              © 2022 K Softworks NV - tüm hakları saklıdır. K Softworks NV,
              Perseusweg 27/A Curacao adresine kayıtlı bir şirkettir. Şans
              oyunları komisyonu tarafından verilmiş çevrimiçi bahis /casino
              oyunları için gerekli olan 8048/JAZ 2016-081 numaralı lisansa
              sahiptir. Maltbahis, Curacao yasalarına uygun olarak, 18 yaşın
              altındaki oyuncuların çevrimiçi bahis /casino sitesini
              kullanmalarına izin vermez.
            </p>{" "}
            <p />
          </div>
        </div>
        <div className="ftr-payments-row-bc">
          <img
            id="4338_Fixturka"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/4338.png"
            alt="Fixturka"
          />
          <img
            id="5688_MPay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5688.png"
            alt="MPay Papara"
          />
          <img
            id="5452_MaksiparaCreditCard"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5452.png"
            alt="MaksiCC"
          />
          <img
            id="5344_InstaPayPaparaV2"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/5344.png"
            alt="InstaPay Papara"
          />
          <img
            id="7090_CepBank"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7090.png"
            alt="CepBank"
          />
          <img
            id="7049_AnindaMFT"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7049.png"
            alt="AnindaMFT"
          />
          <img
            id="1056_CoinPayments"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/1056.png"
            alt="CoinPayments"
          />
          <img
            id="7283_Jupiterbahise Para Cek"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/7283.png"
            alt="WeTransfer"
          />
          <img
            id="10078_InstapaysCrypto"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10078.png"
            alt="Instapay Crypto"
          />
          <img
            id="10795_HedefHavale"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10795.png"
            alt="HedefHavale"
          />
          <img
            id="8006_SafePay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/8006.png"
            alt="SafePay"
          />
          <img
            id="10423_FulgurPay"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10423.png"
            alt="Cryptocurrency"
          />
          <img
            id="12208_FulgurPayFTN"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/default/12208.png"
            alt="FTN"
          />
          <img
            id="12715_Crypto Withdrawal"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/12715.png"
            alt="Crypto Withdrawal"
          />
          <img
            id="4672_Hizlipapara"
            className="ftr-payments-row-img-bc"
            src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/4672.png"
            alt="Hizlipapara"
          />
        </div>
        <div className="ftr-copy-rights-bc">© 2021 - 2023 Maltbahis</div>
      </div>
    </div>
  );
};

export { Footer };
